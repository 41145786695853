<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" md="6" class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                Input groups
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col>
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input input-icon placeholder-light"
                    placeholder="Your name"
                  >
                    <template slot="prepend-inner">
                      <v-icon size=".875rem" color="#adb5bd"
                        >fas fa-user</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input input-icon placeholder-light"
                    placeholder="Email"
                  >
                    <template slot="prepend-inner">
                      <v-icon size=".875rem" color="#adb5bd"
                        >fas fa-envelope</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input input-icon placeholder-light"
                    placeholder="Location"
                  >
                    <template slot="append">
                      <v-icon size=".875rem" color="#adb5bd"
                        >fas fa-map-marker</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input input-icon placeholder-light"
                    placeholder="Password"
                  >
                    <template slot="append">
                      <v-icon size=".875rem" color="#adb5bd">fas fa-eye</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input input-icon placeholder-light"
                    placeholder="Payment method"
                  >
                    <template slot="prepend-inner">
                      <v-icon size=".875rem" color="#adb5bd"
                        >fas fa-credit-card</v-icon
                      >
                    </template>
                    <template slot="append">
                      <small class="font-weight-bold description">USD</small>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input input-icon placeholder-light"
                    placeholder="Phone"
                  >
                    <template slot="prepend-inner">
                      <v-icon size=".875rem" color="#adb5bd"
                        >fas fa-globe-americas</v-icon
                      >
                    </template>
                    <template slot="append">
                      <v-icon size=".875rem" color="#adb5bd"
                        >fas fa-phone</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">Selects</div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" lg="4">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Basic select</label
                  >
                  <v-select
                    :items="items"
                    value="1"
                    class="input-style font-size-input text-light-input placeholder-light select-style"
                    outlined
                    single-line
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" lg="8">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Multiple select</label
                  >
                  <v-select
                    :items="items2"
                    v-model="value"
                    class="input-style font-size-input text-light-input placeholder-light select-style"
                    outlined
                    chips
                    multiple
                    single-line
                    height="46"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip label color="bg-default" class="py-1 px-2 my-0">
                        <span class="text-white text-caption ls-0">{{
                          item
                        }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                Datepicker
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="6">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Date Picker</label
                  >
                  <v-menu
                    ref="menuDate"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :return-value.sync="dateSimple"
                    transition="slide-y-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="23"
                        v-model="dateSimple"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">
                          <v-icon size=".875rem">far fa-calendar</v-icon>
                        </template>
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="dateSimple"
                      no-title
                      scrollable
                      color="bg-primary"
                      header-color="bg-primary"
                      class="card-picker-custom"
                    >
                      <v-spacer></v-spacer>

                      <v-btn
                        @click="menuDate = false"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-600 text-capitalize btn-ls btn-danger py-3 px-6 rounded-sm"
                        color="#f5365c"
                        >Cancel</v-btn
                      >

                      <v-btn
                        small
                        @click="$refs.menuDate.save(dateSimple)"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-600 text-capitalize btn-ls btn-primary py-3 px-6 rounded-sm"
                        color="#5e72e4"
                        >Ok</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Range Picker</label
                  >
                  <v-menu
                    ref="menuDate1"
                    v-model="menuDate1"
                    :close-on-content-click="false"
                    :return-value.sync="dateRangeText"
                    transition="slide-y-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="23"
                        v-model="dateRangeText"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">
                          <v-icon size=".875rem">far fa-calendar</v-icon>
                        </template>
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="dates"
                      range
                      color="bg-primary"
                      header-color="bg-primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                Text Editor
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-form>
                <html-editor></html-editor>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">Tags</div>
            </div>

            <v-card-text class="card-padding">
              <v-chip
                label
                color="bg-default"
                class="py-1 my-0 me-3"
                v-if="chip1"
                text-color="white"
                close
                @click:close="chip1 = false"
              >
                <span class="text-caption ls-0">Bucharest</span>
              </v-chip>
              <v-chip
                label
                text-color="white"
                color="bg-default"
                class="py-1 my-0 me-3"
                v-if="chip2"
                close
                @click:close="chip2 = false"
              >
                <span class="text-caption ls-0">Iasi</span>
              </v-chip>
              <v-chip
                label
                text-color="white"
                color="bg-default"
                class="py-1 my-0"
                v-if="chip3"
                close
                @click:close="chip3 = false"
              >
                <span class="text-caption ls-0">Timisoara</span>
              </v-chip>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                Toggle Buttons
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-switch
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch me-2"
                v-model="switche"
                hide-details
                color="#5e72e4"
                inset
              ></v-switch>
              <v-switch
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch me-2"
                v-model="switche1"
                hide-details
                color="#5e72e4"
                inset
              ></v-switch>
              <v-switch
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch me-2"
                v-model="switche2"
                hide-details
                color="#172b4d"
                inset
              ></v-switch>
              <v-switch
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch me-2"
                v-model="switche3"
                hide-details
                color="#f5365c"
                inset
              ></v-switch>
              <v-switch
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch me-2"
                v-model="switche4"
                hide-details
                color="#fb6340"
                inset
              ></v-switch>
              <v-switch
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch me-2"
                v-model="switche5"
                hide-details
                color="#2dce89"
                inset
              ></v-switch>
              <v-switch
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch me-2"
                v-model="switche6"
                hide-details
                color="#11cdef"
                inset
              ></v-switch>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">Sliders</div>
            </div>

            <v-card-text class="card-padding">
              <v-slider
                v-model="slider"
                color="bg-primary"
                track-color="#e9ecef"
                class="progress-shadow mb-6"
                thumb-label
                hint="Im a hint"
                max="100"
              ></v-slider>

              <v-range-slider
                v-model="range"
                color="bg-primary"
                track-color="#e9ecef"
                class="progress-shadow mb-6"
                thumb-label
                hint="Im a hint"
                max="100"
              ></v-range-slider>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">Dropzone</div>
            </div>

            <v-card-text class="card-padding">
              <dropzone v-model="inputs.fileSingle"></dropzone>

              <dropzone v-model="inputs.fileMultiple" multiple></dropzone>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HeaderTopDashboard from "../../components/HeaderTopDashboard.vue";
import HtmlEditor from "@/components/HtmlEditor";
import Dropzone from "@/components/Dropzone";
export default {
  name: "form-components",
  components: {
    HeaderTopDashboard,
    HtmlEditor,
    Dropzone,
  },
  data() {
    return {
      inputs: {
        fileSingle: [],
        fileMultiple: [],
      },
      slider: 50,
      range: [-20, 70],
      switche: false,
      switche1: true,
      switche2: true,
      switche3: true,
      switche4: true,
      switche5: true,
      switche6: true,
      chip1: true,
      chip2: true,
      chip3: true,
      dates: ["2019-09-10", "2019-09-20"],
      menuDate: false,
      menuDate1: false,
      dateSimple: new Date().toISOString().substr(0, 10),
      value: ["foo", "bar", "fizz", "buzz"],
      items: ["foo", "bar", "fizz", "buzz"],
      items2: ["foo", "bar", "fizz", "buzz"],
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
};
</script>
